<template>
  <v-card-text
    id="report"
    v-if="association && Object.keys(association).length > 0"
  >
    <div id="report_pdf">
      <Report_header :type="note ? 'Summary W Note' : 'Summary Report'" />
      <Top_association :association="association" />
    </div>
    <Summary_data_table
      :payment="payList"
      :headers="note ? 4 : 3"
      :reports="reports"
    />
  </v-card-text>
  <Report_destructor v-else />
</template>

<script>
import { mapGetters } from "vuex";
import { customFilterArr, processMoney } from "@/functions";

export default {
  name: "summaryReport",
  components: {
    Report_destructor: () => import("@/components/report/report_destructor"),
    Summary_data_table: () => import("@/components/report/summary_data_table"),
    Report_header: () => import("@/components/report/report_header"),
    Top_association: () => import("@/components/report/top_association")
  },
  computed: {
    ...mapGetters({ report: "report", summary: "summaryData" }),
    association() {
      return this.reports[
        customFilterArr(this.reports, "Client_Associations")[
          this.report.index || 0
        ].ref[0]
      ];
    },
    payList() {
      const arr = new Array(...this.summary);

      return Math.round(
        arr.reduce((a, b) => {
          return a + processMoney(b.payAmount);
        }, 0)
      );
    }
  },
  methods: {
    async startList() {
      let asses = [];
      const filteredArray = customFilterArr(
        this.reports,
        "Client_Associations"
      );
      const record = filteredArray[this.report.index || 0];
      record.ref.forEach(r => {
        let ass = this.reports[r],
          payments = ass.payments;
        ass.payAmount =
          "$" +
          payments.reduce((a, b) => a + processMoney(b["Payment Amount"]), 0);
        if (payments.length > 0 && this.note) {
          ass.Memo = payments[0].Memo;
        } else {
          ass.Memo = "";
        }
        ass = {
          ...ass,
          Home_Owner: ass["Home Owner"],
          Client_Address: ass["Client Address"]
        };
        asses.push(ass);
      });
      this.$store.commit("summaryData", asses);
    }
  },
  props: {
    reports: {
      type: Array,
      default: () => []
    },
    note: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    note: {
      immediate: true,
      handler() {
        this.startList();
      }
    },
    association: {
      immediate: false,
      handler() {
        this.startList();
      }
    }
  }
};
</script>

<style scoped></style>
